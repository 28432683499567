import axios from 'axios';
import React, { useState } from 'react';
import './App.css';

const API_URL = 'http://54.149.241.22:8080/';
const endpoint = 'something';

function App() {

  const [something, setSomething] = useState('');

  const getSomething = () => {
    let requestURL = API_URL + endpoint;
    axios.get(requestURL).then((response) => {
      console.log(response);
      setSomething(response.data);
    }).catch((ex) => {
      console.error(ex);
      setSomething('Noah is incompitent at programming');
    })
  }

  return (
    <div className="Homepage">
      <p>This is the first text on the website homepage.</p>
      <button className="SomethingButton" onClick={() => {getSomething()}}>Get Something</button>
      <div className='Something'>
        {something}
      </div>
    </div>
  );
}

export default App;
